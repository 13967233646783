import { ComponentProps, ReactNode, useCallback, useEffect } from "react";
import { t, Trans } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { isEmpty, upperFirst } from "lodash";
import { Link, useParams } from "react-router-dom";

import { buildExternalLinkUrl } from "@kavval/analytics";
import { isMultiSports } from "@kavval/constants";
import CalendarIcon from "@kavval/ui/OutlinedIcons/CalendarIcon";
import MountainPicto from "@kavval/ui/Picto/MountainPicto";

import { OfferStatusEnum } from "@api/db/models/Offer/types";

import {
  DisciplineChip,
  DistanceChip,
  ErrorBlock,
  LoadingCentered,
  PageTitle,
  ShoppingCartContainer,
  ShoppingCartLayout,
  SponsorsSection,
  TrackViewItem,
} from "@booking/components";
import GenericNotFoundError from "@booking/components/GenericNotFoundError";
import PageWrapper from "@booking/components/PageWrapper";
import analytics from "@booking/lib/analytics";
import { formatDateRange, formatNumber } from "@booking/lib/formatters";
import useTrackPageView from "@booking/lib/hooks/useTrackPageView";
import { Output, trpc } from "@booking/lib/trpc";
import TicketContainer from "@booking/pages/EventRaces/TicketContainer";
import NotFoundPage from "@booking/pages/NotFound";

import styles from "./styles.module.css";

type Event = NonNullable<Output["getEventRaces"]>;
type RaceEditionData = Event["nextEdition"]["raceEditions"][number];
type Offer = RaceEditionData["tickets"][number];

type TicketProps = {
  ticket: Offer;
  defaultRegistrationUrl: string;
  onBookClick: (offer: Offer) => void;
  event: {
    id: Event["id"];
    slug: Event["slug"];
  };
  showPriceAsterisk?: boolean;
};

const Ticket = ({
  ticket,
  defaultRegistrationUrl,
  onBookClick,
  event,
  showPriceAsterisk,
}: TicketProps) => {
  const { shortId, status, soldOnKavval } = ticket;

  const bookable = status === "available";

  let statusText;

  if (status === "sold_out") {
    statusText = t({ id: "page.tickets.status.soldOut", message: "Complet" });
  } else if (status === "expired") {
    statusText = t({ id: "page.tickets.status.expired", message: "Ventes clôturées" });
  } else if (status === "registration_not_opened") {
    statusText = t({ id: "page.tickets.status.notOpened", message: "Ventes non ouvertes" });
  }

  const onClick = useCallback(() => {
    onBookClick(ticket);
  }, [onBookClick, ticket]);

  return (
    <TicketContainer
      bookable={bookable}
      title={ticket.title}
      description={ticket.description}
      price={{
        amount: ticket.price,
        currency: ticket.currency,
        isMinPrice: ticket.isMinPrice,
        fees: ticket.fees,
      }}
      limitedToEmployees={ticket.limitedToEmployees}
      availableAt={ticket.availableAt}
      expiresAt={ticket.expiresAt}
      maxRegistrations={ticket.maxRegistrations}
      maxBirthdate={ticket.maxBirthdate}
      minBirthdate={ticket.minBirthdate}
      remainingRegistrations={ticket.remainingRegistrations}
      showPriceAsterisk={showPriceAsterisk}
      button={
        bookable ? (
          soldOnKavval ? (
            <Link
              className="md:mt-2 button button button-primary"
              to={`/ticket/${shortId}`}
              onClick={onClick}
            >
              <Trans id="page.tickets.bookNow">Réserver</Trans>
            </Link>
          ) : (
            <a
              className="md:mt-2 button button button-primary"
              href={buildExternalLinkUrl({
                href: ticket.registrationUrl || defaultRegistrationUrl,
                pageId: event.id,
                pageSlug: event.slug,
                showAds: false,
                type: "registration",
              })}
              target="_blank"
              onClick={onClick}
              rel="noreferrer"
            >
              <Trans id="page.tickets.bookNow">Réserver</Trans>
            </a>
          )
        ) : (
          <span className="md:mt-2 leading-none text-center text-dark-blue/80 bg-dark-blue/5 font-semibold text-sm py-1 px-2">
            {statusText}
          </span>
        )
      }
    />
  );
};

const Race = ({
  raceEdition,
  event,
  showPriceAsterisk,
}: {
  raceEdition: RaceEditionData;
  event: Event;
  showPriceAsterisk?: boolean;
}) => {
  const { i18n } = useLingui();

  const { race, elevationGain, elevationLoss, tickets } = raceEdition;

  const elevationText = elevationGain
    ? [
        elevationGain && `${formatNumber(elevationGain)}mD+`,
        elevationLoss && `${formatNumber(elevationLoss)}mD-`,
      ]
        .filter(Boolean)
        .join(" / ")
    : null;

  const onBookClick: ComponentProps<typeof Ticket>["onBookClick"] = useCallback(
    (ticket) => {
      analytics.trackAddToCart({ event, race, offer: ticket });
    },
    [event, race]
  );

  let ticketsContent: Array<ReactNode> = [];
  let showWaitingListOffer = event.isWaitingListEnabled;
  let waitingListType: "opening" | "reopening" = "opening";

  if (raceEdition.status === "cancelled") {
    showWaitingListOffer = false;
    ticketsContent = [
      <ErrorBlock className="my-6 text-center" key="cancelled">
        <Trans id="page.tickets.raceEditionCancelled">Course annulée</Trans>
      </ErrorBlock>,
    ];
  } else if (raceEdition.status === "tba") {
    // On ne connait pas encore la date mais on affiche la course
    waitingListType = "opening";
  } else if (!isEmpty(tickets)) {
    ticketsContent = tickets.map((ticket) => (
      <Ticket
        onBookClick={onBookClick}
        key={ticket.id}
        ticket={ticket}
        defaultRegistrationUrl={raceEdition.registrationUrl || event.registrationUrl!}
        event={event}
        showPriceAsterisk={showPriceAsterisk}
      />
    ));

    if (tickets.some((ticket) => ticket.status === OfferStatusEnum.Available)) {
      // Si au moins une des offres est ouverte on n'affiche pas la liste d'attente
      showWaitingListOffer = false;
    } else if (tickets.some((ticket) => ticket.status === OfferStatusEnum.RegistrationNotOpened)) {
      // Si une des offres va bientôt être dispo (aucune n'est ouverte à ce stade)
      waitingListType = "opening";
    } else {
      // À ce stade :
      // - aucune offre n'est ouverte
      // - aucune offre ne va bientôt ouvrir
      // Il reste donc des offres expirées ou sold-out (ou rien), on affiche donc la liste d'attente en
      // mode "réouverture".
      waitingListType = "reopening";
    }
  } else {
    if (raceEdition.registrationUrl) {
      // Il n'y a aucune offre mais il y a un lien d'inscription
      // On l'affiche et on désactive la liste d'attente
      showWaitingListOffer = false;
      ticketsContent.push(
        <TicketContainer
          key={raceEdition.id}
          bookable
          title={raceEdition.race.formattedTitle}
          button={
            <a
              className="md:mt-2 button button button-primary"
              href={raceEdition.registrationUrl}
              target="_blank"
              rel="noreferrer"
            >
              <Trans id="page.tickets.registerButton">M'inscrire</Trans>
            </a>
          }
        />
      );
    } else if (!showWaitingListOffer) {
      // Il n'y a aucune offre. Si la liste d'attente n'est pas activée on ne retourne rien.
      return null;
    }

    // Il s'agit forcément d'une liste d'attente en mode "réouverture".
    waitingListType = "opening";
  }

  if (showWaitingListOffer) {
    ticketsContent.push(
      <TicketContainer
        key={raceEdition.id}
        noReveal
        bookable
        title={t({ id: "page.tickets.waitingListTitle", message: "Liste d'attente" })}
        description={
          waitingListType === "opening"
            ? t({
                id: "page.tickets.waitingListDescription.opening",
                message:
                  "Les inscriptions ne sont pas encore ouvertes pour cette course. Inscris-toi sur la liste d'attente pour être prévenu en priorité.",
              })
            : t({
                id: "page.tickets.waitingListDescription.reopening",
                message:
                  "Les inscriptions sont closes pour cette course. Inscris-toi sur la liste d'attente pour être prévenu en priorité si des places se libèrent.",
              })
        }
        button={
          <Link
            className="md:mt-2 button button button-primary"
            to={`/waiting-list/${raceEdition.id}`}
          >
            <Trans id="page.tickets.registerButton">M'inscrire</Trans>
          </Link>
        }
      />
    );
  }

  const startDate = `${raceEdition.date}T${raceEdition.time || "00:00"}`;
  const endDate = raceEdition.endDate
    ? `${raceEdition.endDate}T${raceEdition.endTime || "00:00"}`
    : startDate;

  return (
    <div className={styles.Race} id={`race-${race.id}`}>
      <div className="font-bold text-xl md:text-2xl md:flex md:flex-row md:flex-wrap md:gap-1">
        <span className="grow leading-tight mr-3">{race.formattedTitle}</span>
        <div className="flex gap-2 md:gap-3 mt-2 flex-wrap md:mt-0">
          {raceEdition.activities.map((activity, index) => (
            <DistanceChip
              distanceUnit={activity.distanceUnit}
              distance={activity.distance}
              key={`${activity}-${index}`}
              // On affiche l'icône pour les disciplines multisports
              activity={isMultiSports(race.discipline) ? activity.activity : undefined}
            />
          ))}
          <DisciplineChip discipline={race.discipline} />
        </div>
      </div>
      <div className="text-sm text-storm-grey font-semibold flex flex-row flex-wrap items-center mt-2 md:mt-1 mb-3">
        <span className="inline-flex flex-row items-center mr-3">
          <CalendarIcon className="text-dark-blue mr-1" />
          {upperFirst(
            formatDateRange(i18n.locale, startDate, endDate, {
              startTime: raceEdition.time,
              endTime: raceEdition.endTime,
            })
          )}
        </span>

        {elevationText && (
          <span className="inline-flex flex-row items-center mr-3">
            <MountainPicto className="text-dark-blue mr-1" />
            {elevationText}
          </span>
        )}
      </div>
      <div className="-mb-6">{ticketsContent}</div>
    </div>
  );
};

const TrackPage = () => {
  useTrackPageView({ pageType: "book/event/offers" });
  return null;
};

const EventRaces = () => {
  const { eventShortId } = useParams();
  const { isLoading, error, data: event } = trpc.getEventRaces.useQuery(eventShortId!);

  const items = event?.nextEdition.raceEditions
    ?.map((raceEdition) =>
      raceEdition.tickets.map((ticket) => ({
        event,
        race: raceEdition.race,
        offer: ticket,
      }))
    )
    .flat();

  useEffect(() => {
    if (!isLoading && event && document.location.hash) {
      setTimeout(() => {
        document.querySelector(document.location.hash)?.scrollIntoView?.();
      }, 500);
    }
  }, [isLoading, event]);

  if (isLoading) {
    return (
      <PageWrapper>
        <LoadingCentered />
      </PageWrapper>
    );
  }

  if (error) {
    return (
      <PageWrapper>
        <GenericNotFoundError tracking={{ pageType: "book/event/offers/error" }} />
      </PageWrapper>
    );
  }

  if (!event) {
    return <NotFoundPage pageType="book/event/offers/not-found" />;
  }

  if (
    (!event.nextEdition || event.nextEdition.status !== "confirmed") &&
    !event.isWaitingListEnabled
  ) {
    document.location.href = event.href;
  }

  const showTVAWarning = event.id === "231b382a-1f06-47c1-a50a-bf2a13b12c5d"; // Marathon de la loire
  // eslint-disable-next-line lingui/no-unlocalized-strings
  const tvaWarning = "* Prix TTC, taux de TVA détaillés dans l’article 5 du règlement de la course";

  return (
    <PageWrapper logoHref={event.href}>
      <ShoppingCartLayout
        withSellingPoints
        rightContent={
          <>
            <ShoppingCartContainer event={event} />
            {showTVAWarning && (
              // eslint-disable-next-line lingui/no-unlocalized-strings
              <div className="text-xs text-storm-grey mt-4 hidden md:block">{tvaWarning}</div>
            )}
          </>
        }
      >
        <TrackPage />
        {items && <TrackViewItem items={items} />}
        <PageTitle
          title={t({ id: "page.booking.title", message: "Inscription à la course" })}
          subtitle={t({
            id: "page.tickets.subtitle",
            message: "Choisis ta distance",
          })}
        />
        {event.nextEdition?.raceEditions?.map((raceEdition) => (
          <Race
            raceEdition={raceEdition}
            key={raceEdition.id}
            event={event}
            showPriceAsterisk={showTVAWarning}
          />
        ))}
      </ShoppingCartLayout>
      {showTVAWarning && (
        // eslint-disable-next-line lingui/no-unlocalized-strings
        <div className="text-xs text-storm-grey mt-8 block md:hidden">{tvaWarning}</div>
      )}
      {!isEmpty(event.sponsors) && <SponsorsSection sponsors={event.sponsors} />}
    </PageWrapper>
  );
};

export default EventRaces;
